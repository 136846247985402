<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Nạp tiền" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
        <template #right>
          <span class="nav-right" @click="$router.push({path:'/RechargeRecord'})">Lịch sử nạp tiền</span>
        </template>
      </van-nav-bar>
    </div>
    <div class="box">
      <div class="box-recharge">
        <div @click="toggle1()" class="head-box">
          <img src="/img/mine/bank.png" />
          <div>THẺ NGÂN HÀNG</div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
        <div v-if="showContent1" class="content-box">
          <div class="moneyNumber">
            <span class="moneyType">Số tiền</span>
            <van-field
              v-model="recharge_money"
              type="number"
              placeholder="Nhập số tiền cần nạp"
            />
          </div>
          <div class="title-ck">Tỷ lệ quy đổi 1:1000</div>
          <table class="table">
            <tr>
              <td>Ngân hàng</td>
              <td>{{ this.$store.getters.getBaseInfo.bank ? this.$store.getters.getBaseInfo.bank : "Đang cập nhật" }}</td>
            </tr>
            <tr>
              <td>Tên người nhận</td>
              <td>{{ this.$store.getters.getBaseInfo.bank_name ? this.$store.getters.getBaseInfo.bank_name : "Đang cập nhật" }}</td>
            </tr>
            <tr>
              <td>Số tài khoản</td>
              <td>{{ this.$store.getters.getBaseInfo.bank_card ? this.$store.getters.getBaseInfo.bank_card : "Đang cập nhật" }}</td>
            </tr>
          </table>
          <van-button class="recharge_btn" type="default" @click="doRecharge()"
            >Xác nhận</van-button
          >
        </div>
      </div>
      <div class="box-recharge">
        <div @click="toggle2()" class="head-box">
          <img src="/img/mine/ewallet.png" />
          <div>VÍ ĐIỆN TỬ</div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
        <div v-if="showContent2" class="content-box">
          <div style="padding: 15px 0">Vui lòng liên hệ bộ phận chăm sóc khách hàng</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      showContent1: false,
      showContent2: false,
      recharge_money: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("Ngoại tuyến");
            localStorage.clear();
            this.$router.push({ path: "/Login" });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    doRecharge() {
      if (this.recharge_money <= 0) {
        this.$toast("Vui lòng điền số tiền chính xác");
        return false;
      } else {
        this.$http({
          method: "post",
          data: { money: this.recharge_money, payment: "Yêu cầu nạp tiền" },
          url: "user_set_recharge",
        }).then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            //this.getUserInfo();
            this.$router.push({ path: "/RechargeRecord" });
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      }
    },
    toggle1() {
      this.showContent1 = !this.showContent1;
    },
    toggle2() {
      this.showContent2 = !this.showContent2;
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "Đăng nhập / Đăng ký";
      this.userInfo.ip = "Đăng nhập để tận hưởng nhiều dịch vụ hơn！";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.box {
  padding: 15px 0;
}
.box-recharge {
  margin-bottom: 15px;
  border-radius: 15px;
  overflow: hidden;
}
.box-recharge > .content-box {
  padding: 15px;
  border-top: 1px solid rgba(153,153,153,0.2);
  background: #fff;
}
.box-recharge > .head-box {
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #c24491;
}
.box-recharge img {
  max-width: 40px;
  border-radius: 10px;
  margin-right: 20px;
}
.box-recharge i {
  margin-left: auto;
  font-size: 18px;
}
.moneyNumber {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  font-size: 1rem;
}
.moneyType {
  width: 120px;
  text-align: left;
}
.recharge_btn {
  background: linear-gradient(
            90deg,#775fd9,#c24491);
  color: #f2f2f2;
  border-radius: 30px;
  font-size: 1.1rem;
  width: 50%;
  margin: 0 25% 15px;
}
::v-deep .van-cell {
  padding: 0;
}
::v-deep .van-field__control {
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid #ced4da;
  color: #c24491;
}
.title-ck {
  margin: 20px 0 10px;
  line-height: 1.2;
  color: #c24491;
  font-size: 15px;
  text-align: left;
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 15px;
}
table td {
    border: 1px solid rgba(153,153,153,0.2);
    vertical-align: middle;
    padding: 12px 8px;
    line-height: 1.2;
    text-align: left;
}
</style>
